<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              to="/insurance/renewal/import"
              variant="primary"
            >
              <FeatherIcon icon="UploadIcon" />
              Data Aktarımı
            </b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(title)="data">
              {{ data.item.title }}
            </template>
            <template #cell(status)="data">
              <b-badge :variant="data.item.status === '1'? 'success' : 'warning'">
                {{ data.item.status === '1'? 'Aktif' : 'Kapalı' }}
              </b-badge>
            </template>
            <template #cell(control)="data">
              <list-dropdown-buttons
                :primary-action="'/insurance/renewal/periods/view/' + data.item.id"
                primary-text="Görüntüle"
                :data-id="data.item.id"
                :delete-action="removeList"
              />
            </template>
          </b-table></b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BBadge,
} from 'bootstrap-vue'
import ListDropdownButtons from '@/layouts/components/common/ListDropdownButtons.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BBadge,
    ListDropdownButtons,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'title',
          label: 'Dönem Adı',
        },
        {
          key: 'status',
          label: 'Durum',
          thClass: 'width-100',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thClass: 'width-100',
        },
      ],
      dataQuery: {
        select: [
          'com_insurance_renewal_periods.id AS id',
          'com_insurance_renewal_periods.title AS title',
          'com_insurance_renewal_periods.status AS status',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['insuranceRenewalPeriods/dataList']
    },
    dataCount() {
      return this.$store.getters['insuranceRenewalPeriods/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('insuranceRenewalPeriods/getDataList', this.dataQuery)
    },
    removeList(id) {
      this.$store.dispatch('insuranceRenewalPeriods/removeData', id)
    },
  },
}
</script>

<style scoped>
</style>
